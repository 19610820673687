.box-info {
    position: absolute;
    /* background-color: rgb(242, 241, 248, .5); */
    background-color: rgba(255, 255, 255, 0.8);
    width: 90%;
    min-height: 50px;
    z-index: 1000;
    margin: 0;
    border-radius: 8px ;
}
